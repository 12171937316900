<div class="typesSelect" (click)="toggleOpen()" [ngClass]="openClass ? '_open' : ''">
    <select class="typesSelect-select" [class._disabled]="disabled" [disabled]="disabled">
        <option>{{selectTitle}}</option>
    </select>
    <div class="typesSelect-hide"></div>
    <div class="typesSelect-list dropdown-menu" role="menu">
        <div class="typesSelect-list-container">
            <div class="typesSelect-list-item _all">
                <a-checkbox [label]="'common.all' | translate"
                            (ngModelChange)="allChecked($event)"
                            [disabled]="disabled"
                            [(ngModel)]="allSelected"
                            size="small">
                </a-checkbox>
                <hr>
            </div>
            <div class="typesSelect-list-subContainer">
                <div class="typesSelect-list-item" *ngFor="let mapEntry of types.types">
                    <a-checkbox [label]="mapEntry.displayName | translate"
                                [disabled]="disabled"
                                (ngModelChange)="onModelChange($event, mapEntry)"
                                [(ngModel)]="typesSelected[mapEntry.value]"
                                size="small">
                    </a-checkbox>
                </div>
            </div>
        </div>
    </div>
</div>
