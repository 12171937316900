<div class="cell shrink">
    <div class="grid-x grid-margin-x align-middle align-justify">
        <h1 class="cell auto">{{'accounting.billings.list.title' | translate}}</h1>
        <a-button-default class="cell shrink" (click)="downloadCsv()"><i
            class="fas fa-download"></i>{{'common.action.export' | translate}}</a-button-default>
        <a-split-button class="cell shrink" [loading]="creationButtonLoading"
                        [visibility]="'HIGH'"
                        (listButtonClicked)="onListButtonClicked($event)"
                        (clicked)="creationButtonLoading = true; navigationService.goToQuoteCreationPage()"
                        [buttonsList]="[
                            {
                                label: 'Nouvelle facture',
                                icon: 'fa-plus'
                            }
        ]"><i class="fas fa-plus"></i> Nouveau devis</a-split-button>
    </div>
</div>

<spacer size="16"></spacer>

<o-filters class="cell shrink" [countsMap]="countsMap">
    <m-search-query
        class="cell auto"
        [(ngModel)]="searchQuery"
        (change)="search()"
        [placeholder]="'accounting.billings.list.search.placeholder' | translate"></m-search-query>

    <m-type-select-query class="cell shrink"
                         [(types)]="billingTypes"
                         (typesChange)="onTypesChange($event)"
                         queryName="billingTypes"></m-type-select-query>

    <m-type-select-query class="cell shrink"
                         [selectTitle]="'accounting.billings.list.search.status' | translate"
                         [(types)]="billingStatus"
                         (change)="search()"
                         [disabled]="selectedBillingTypes.length !== 1"
                         queryName="billingStatus"></m-type-select-query>

    <m-date-picker-query class="cell shrink"
                         [placeholder]="'accounting.billings.list.search.from' | translate"
                         [ngModel]="fromDate"
                         (ngModelChange)="onFromDateChange($event)"
                         queryName="fromDate"></m-date-picker-query>

    <m-date-picker-query class="cell shrink"
                         [placeholder]="'accounting.billings.list.search.to' | translate"
                         [ngModel]="toDate"
                         (ngModelChange)="onToDateChange($event)"
                         queryName="toDate"></m-date-picker-query>
</o-filters>

<spacer [size]="16"></spacer>

<div class="cell auto grid-y">
    <o-table [headers]="billingHeaders" (scroll)="loadNext()" [loading]="billingsLoading" [isEmpty]="billingDocuments.length == 0">
        <tr *ngFor="let billingDocument of billingDocuments">
            <td>
                <div class="billingsIcon" m-icon [type]="billingDocument.documentType"></div>
            </td>
            <td>
                <a (click)="navigationService.goToBillingPage(billingDocument)"
                   [ngClass]="'_' + billingDocument.documentType.toLowerCase()">
                    {{billingDocument.reference}}
                </a>
            </td>
            <td>
                <span class="billingStatus" [ngClass]="'_' + billingDocument.getStatus().toLowerCase()">
                    {{'billings.status.' + billingDocument.documentType + '.' + billingDocument.getStatus() | translate}}
                </span>
            </td>
            <td><a
                [routerLink]="['/boa', 'customers', billingDocument.customerAccountId]">{{billingDocument.customerName}}</a>
            </td>
            <td>{{billingDocument.date | boaDate}}</td>
            <td class="text-right">
                <span class="_tableNumber">{{billingDocument.netAmount.value | currency : 'EUR'}}</span>
            </td>
        </tr>
        <tr *ngIf="billingDocuments.length === 0"
            [columnNumber]="billingHeaders.length"
            [emptyLabel]="'accounting.billings.list.table.empty.title' | translate"
            o-empty-table>{{'accounting.billings.list.table.empty.message' | translate}}
        </tr>
    </o-table>
</div>

