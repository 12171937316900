import {Component, HostBinding, OnInit} from '@angular/core';
import {TableHeader} from '../../../../shared/models/table-header';
import {BillingDocumentType, CreditNoteStatus, ListBillingDocument} from '../../models/invoices/billing-document';
import {ActivatedRoute} from '@angular/router';
import {BillingDocumentService} from '../../services/billing-document.service';
import {Result} from '../../../common/models/shared/result';
import {take} from 'rxjs/operators';
import {TypeList} from '../../../../shared/models/type-list';
import {TypeEntry} from '../../../../shared/models/type-entry';
import {InvoiceStatus} from '../../models/invoices/invoice';
import {QuoteStatus} from '../../models/invoices/quote';
import {TranslateService} from '@ngx-translate/core';
import {DownloadService} from '../../../common/services/download.service';
import {NavigationService} from '../../../common/services/navigation.service';
import {setTableHeaderSort} from '../../../../shared/functions/sort-utils';
import {DatePipe} from '@angular/common';
import {Subscription} from 'rxjs';

@Component({
    selector: 'boa-billings-list',
    templateUrl: './billings-list.component.html',
    styleUrls: ['./billings-list.component.scss']
})

export class BillingsListComponent implements OnInit {

    @HostBinding('attr.class') class = 'cell auto grid-y';

    billingDocuments: Array<ListBillingDocument>;
    billingHeaders: Array<TableHeader> = [];
    page = 0;
    step = 30;
    searchQuery: string;
    fromDate: Date;
    toDate: Date;

    billingTypes = new TypeList(
        [
            new TypeEntry('Facture', BillingDocumentType.INVOICE, true),
            new TypeEntry('Devis', BillingDocumentType.QUOTE, true),
            new TypeEntry('Avoir', BillingDocumentType.CREDIT, true),
        ]
    );
    billingStatus = new TypeList([]);
    quoteBillingStatus = new TypeList(
        [
            new TypeEntry(this.translateService.instant('billings.status.QUOTE.' + QuoteStatus.IN_PROGRESS),
                QuoteStatus.IN_PROGRESS, false),
            new TypeEntry(this.translateService.instant('billings.status.QUOTE.') + QuoteStatus.DENIED,
                QuoteStatus.DENIED, false),
            new TypeEntry(this.translateService.instant('billings.status.QUOTE.') + QuoteStatus.CANCELED,
                QuoteStatus.CANCELED, false),
            new TypeEntry(this.translateService.instant('billings.status.QUOTE.') + QuoteStatus.VALIDATED,
                QuoteStatus.VALIDATED, false)
        ]
    );
    invoiceBillingStatus = new TypeList(
        [
            new TypeEntry(this.translateService.instant('billings.status.INVOICE.') + InvoiceStatus.IN_PROGRESS,
                InvoiceStatus.IN_PROGRESS, false),
            new TypeEntry(this.translateService.instant('billings.status.INVOICE.') + InvoiceStatus.CUSTOMER_SENT,
                InvoiceStatus.CUSTOMER_SENT, false),
            new TypeEntry(this.translateService.instant('billings.status.INVOICE.') + InvoiceStatus.OVERDUE,
                InvoiceStatus.OVERDUE, false),
            new TypeEntry(this.translateService.instant('billings.status.INVOICE.') + InvoiceStatus.PAID,
                InvoiceStatus.PAID, false),
            new TypeEntry(this.translateService.instant('billings.status.INVOICE.') + InvoiceStatus.PARTLY_PAID,
                InvoiceStatus.PARTLY_PAID, false)
        ]
    );
    creditNoteStatus = new TypeList(
        [
            new TypeEntry(this.translateService.instant('billings.status.CREDIT.') + 'VALIDATED',
                'VALIDATED', false)
            ]
    );

    sort = '';
    countsMap: Map<string, number>;
    csvLink: string;
    selectedBillingTypes: string[] = [];
    customerAccountId: string;
    creationButtonLoading: boolean;
    billingsLoading: boolean;

    oldSelectedType: BillingDocumentType = undefined;
    private searchSubscription: Subscription | null = null;
    private delivered: boolean | null = null;
    private totalCount: number;

    constructor(private route: ActivatedRoute,
                public navigationService: NavigationService,
                private translateService: TranslateService,
                private datePipe: DatePipe,
                private downloadService: DownloadService,
                private billingDocumentService: BillingDocumentService) {
        this.csvLink = billingDocumentService.getCsvLink();
    }

    private allOrInvoiceStatus(element: string): InvoiceStatus | 'ALL' {
        return element === 'ALL' ? element : InvoiceStatus[element];
    }

    private allOrQuoteStatus(element: string): QuoteStatus | 'ALL' {
        return element === 'ALL' ? element : QuoteStatus[element];
    }

    private allOrCreditNoteStatus(element: string): CreditNoteStatus | 'ALL' {
        return element === 'ALL' ? element : CreditNoteStatus[element];
    }

    private allOrBillingDocumentType(element: string): BillingDocumentType | 'ALL' {
        return element === 'ALL' ? element : BillingDocumentType[element];
    }

    public setAllStatusSelectedIfTypesChanged(): void {
        if (!this.oldSelectedType) {
            // first time
            this.oldSelectedType = BillingDocumentType[this.selectedBillingTypes[0]];
            return;
        }
        if (this.selectedBillingTypes.length === 1 && this.oldSelectedType.toString() !== this.selectedBillingTypes[0]) {
            // types changed
            this.billingStatus.setAllSelected();
            this.oldSelectedType = BillingDocumentType[this.selectedBillingTypes[0]];
        }
    }

    loadNext(): void {
        if (this.billingDocuments.length < this.totalCount) {
            this.page = this.page + 1;
            this.billingsLoading = true;

            this.billingDocumentService.find(
                this.searchQuery,
                this.fromDate,
                this.toDate,
                this.billingTypes.getSelectedValues().map(el => this.allOrBillingDocumentType(el)),
                this.billingStatus.getSelectedValues().map(el => {
                    if (this.billingTypes.getSelectedValues().length === 1 &&
                        this.billingTypes.getSelectedValues()[0] === BillingDocumentType.INVOICE) {
                        return this.allOrInvoiceStatus(el);
                    } else if (this.billingTypes.getSelectedValues().length === 1 &&
                        this.billingTypes.getSelectedValues()[0] === BillingDocumentType.QUOTE) {
                        return this.allOrQuoteStatus(el);
                    }
                }),
                this.step,
                this.page * this.step, this.sort,
                this.customerAccountId,
                this.delivered
            ).subscribe(result => {
                this.billingsLoading = false;
                this.billingDocuments = this.billingDocuments.concat(result);
            });
        }
    }

    search(): void {
        this.page = 0;

        if (this.searchSubscription != null) {
            this.searchSubscription.unsubscribe();
        }

        this.searchSubscription = this.billingDocumentService.findPaginated(
            this.searchQuery,
            this.fromDate,
            this.toDate,
            this.billingTypes.getSelectedValues().map(el => this.allOrBillingDocumentType(el)),
            this.billingStatus.getSelectedValues().map(el => {
                if (this.billingTypes.getSelectedValues()[0] === BillingDocumentType.INVOICE) {
                    return  this.allOrInvoiceStatus(el);
                } else if (this.billingTypes.getSelectedValues()[0] === BillingDocumentType.QUOTE) {
                    return  this.allOrQuoteStatus(el);
                }
            }),
            this.step,
            this.page * this.step,
            this.sort,
            this.customerAccountId,
            this.delivered
        ).subscribe(result => {
            this.billingDocuments = result.data;
            this.countsMap = new Map(
                [
                    [this.translateService.instant('common.total'), result.totalCount]
                ]
            );
        });
    }

    downloadCsv(): void {
        this.downloadService.downloadFileWithParams(
            this.csvLink,
            `documents-comptables-${this.datePipe.transform(Date.now(), 'dd/MM/yy')}`,
            'csv',
            {
                search: this.searchQuery,
                billingTypes: this.billingTypes.getSelectedValues(),
                fromDate: this.fromDate?.toISOString(),
                toDate: this.toDate?.toISOString(),
                billingStatus: this.billingStatus.getSelectedValues()?.join(','),
                sort: this.sort,
                customerAccountId: this.customerAccountId
            }
        );
    }

    setAppropriateStatusList(billingTypes: TypeList): void {
        this.selectedBillingTypes = billingTypes.getSelectedValues();
        if (this.selectedBillingTypes.length !== 1) {
            this.billingStatus = new TypeList([]);
            return;
        } else if (this.selectedBillingTypes.includes(BillingDocumentType.INVOICE)) {
            this.billingStatus = this.invoiceBillingStatus;
            return;
        } else if (this.selectedBillingTypes.includes(BillingDocumentType.QUOTE)) {
            this.billingStatus = this.quoteBillingStatus;
            return;
        } else if (this.selectedBillingTypes.includes(BillingDocumentType.CREDIT)) {
            this.billingStatus = this.creditNoteStatus;
            return;
        }
    }

    onListButtonClicked(buttonId: number): void {
        if (buttonId === 0) {
            this.creationButtonLoading = true;
            this.navigationService.goToInvoiceCreationPage();
        }
    }
    ngOnInit(): void {
        this.loadHeadersLabels();

        this.route.data.subscribe((data: { billingDocuments: Result<ListBillingDocument> }) => {
            this.billingDocuments = data.billingDocuments.data;
            this.totalCount = data.billingDocuments.totalCount;
            this.countsMap = new Map(
                [
                    ['common.lists.results.number', data.billingDocuments.totalCount]
                ]
            );
        });

        this.route.queryParamMap.pipe(take(1)).subscribe(params => {
            this.searchQuery = params.get('search');
            if (params.has('fromDate')) {
                this.fromDate = new Date(params.get('fromDate'));
            }
            if (params.has('toDate')) {
                this.toDate = new Date(params.get('toDate'));
            }
            this.billingTypes.setSelected(params.getAll('billingTypes').map(el => this.allOrBillingDocumentType(el)));
            this.setAppropriateStatusList(this.billingTypes);
            this.billingStatus.setSelected(params.getAll('billingStatus').map(el => {
                if (this.billingTypes.getSelectedValues()[0] === BillingDocumentType.INVOICE) {
                    return this.allOrInvoiceStatus(el);
                } else if (this.billingTypes.getSelectedValues()[0] === BillingDocumentType.QUOTE) {
                    return this.allOrQuoteStatus(el);
                } else if (this.billingTypes.getSelectedValues()[0] === BillingDocumentType.CREDIT) {
                    return this.allOrCreditNoteStatus(el);
                }
            }));
            this.sort = params.get('sort');
            setTableHeaderSort(this.billingHeaders, this.sort);
            this.customerAccountId = params.get('customerAccountId');

            if (params.has('isDelivered')) {
                this.delivered = params.get('isDelivered') === 'true';
            }

            this.setAllStatusSelectedIfTypesChanged();
        })
    }

    private loadHeadersLabels() {
        this.translateService.get([
            'accounting.billings.list.type',
            'accounting.billings.list.reference',
            'accounting.billings.list.status',
            'accounting.billings.list.customer',
            'accounting.billings.list.date',
            'accounting.billings.list.amount'
        ]).subscribe(translations =>
            this.billingHeaders = [
                new TableHeader(translations['accounting.billings.list.type'], false, 'TYPE', null),
                new TableHeader(translations['accounting.billings.list.reference'], false, 'REFERENCE', null),
                new TableHeader(translations['accounting.billings.list.status'], false, 'CUSTOMER.STATUS', null),
                new TableHeader(translations['accounting.billings.list.customer'], true, 'CUSTOMER.NAME', null),
                new TableHeader(translations['accounting.billings.list.date'], true, 'DATE', null),
                new TableHeader(translations['accounting.billings.list.amount'], true, 'NET_AMOUNT.VALUE', 'billingAmount',
                    false, null, null, null)
            ]);
    }

    onTypesChange(billingTypes: TypeList): void {
        this.setAppropriateStatusList(billingTypes);
        this.search();
    }

    onFromDateChange(fromDate: Date) {
        if (fromDate !== this.fromDate) {
            this.fromDate = fromDate;
            this.search();
        }
    }

    onToDateChange(toDate: Date) {
        if (toDate !== this.toDate) {
            this.toDate = toDate;
            this.search();
        }
    }
}
