<div class="customerMenus" *ngIf="billingDocument?.billingDocumentAdditionalInformation?.deliverTokenToOtherCustomers">
    <div class="customerMenus-item" [class._active]="isChargedCustomerDisplayed()"
         (click)="displayCustomerType('CHARGED')" [title]="'invoice.charged.customer.title' | translate">
        <i class="fas fa-file-invoice-dollar"></i>
    </div>
    <div class="customerMenus-item" [class._active]="areTokenCustomersDisplayed()"
         (click)="displayCustomerType('TOKEN')" [title]="'invoice.token.customers.title' | translate">
        <i class="fas fa-truck"></i>
    </div>
</div>

<div class="customer" *ngIf="billingDocument">
    <o-invoice-charged-customer
        [customerMenu]="billingDocument?.billingDocumentAdditionalInformation?.deliverTokenToOtherCustomers"
        [(invalid)]="invalid"
        [dirty]="dirty"
        [disabled]="disabled"
        [customerAccount]="customerAccount"
        (customerAccountChange)="customerAccountChange.emit($event)"
        [allCustomersCode]="allCustomersCode"
        [allCustomersName]="allCustomersName"
        (vatRateChange)="vatRateChange.emit($event)"
        [billingDocument]="billingDocument"
        [hidden]="areTokenCustomersDisplayed()"
        [chorusProSettings]="chorusProSettings"
        (dirtyChange)="dirtyChange.emit($event)"
    ></o-invoice-charged-customer>

    <o-invoice-token-customers
        [tokenDeliveryCustomersTarget]="billingDocument?.billingDocumentAdditionalInformation?.additionalCustomerAccountsForTokenDelivery"
        [hidden]="isChargedCustomerDisplayed()"
        [allCustomersCode]="allCustomersCode"
        [allCustomersName]="allCustomersName"
        (tokenDeliveryCustomersTargetUpdated)="onTokenDeliveryCustomersTargetUpdate($event)"
        [chargedCustomerId]="customerAccount.id"
        [disabled]="disabled">
    </o-invoice-token-customers>

    <spacer [size]="16"></spacer>

    <a-checkbox [(ngModel)]="billingDocument.billingDocumentAdditionalInformation.deliverTokenToOtherCustomers" (change)="onDeliverTokenToOtherCustomersToggle($event)"
                [label]="'invoice.customer.deliver.to.other.accounts' | translate" [size]="'small'"
                *ngIf="isChargedCustomerDisplayed() && !disabled"></a-checkbox>
</div>
